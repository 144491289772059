import React, { useState } from 'react'
import styles from './PodDetails.module.scss'
import { PodDetails } from './PodDetailsTypes'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ToggleButton from '@material-ui/lab/ToggleButton'
import classNames from 'classnames'

type Props = {
  data: PodDetails
  isAdmin: boolean
}
export const pluralize = (count: number, noun: string, showCount = true) =>
`${showCount ? `${count} ` : ''}${noun}${count === 1 ? '' : 's'}`

function PodsDetails (props: Props) {
  const {
    podName,
    availailtiy,
    trafficScore,
    booked, 
    wellnessScore
  } = props.data
  
  const [buttonHolding, setButtonHolding] = useState(false)
  
  const formatTime = (time:string) => {
    const breakdown =time.split(":")
    let formated =''
    if (parseInt(breakdown[0]) > 0 ){
      formated = pluralize(parseInt(breakdown[0]) ,"hr") +" "
    }
    if (parseInt(breakdown[1]) >= 0 ){
      formated = formated +  pluralize(parseInt(breakdown[1]) ,"min")
    }

    return formated
  }

  const getStatus = () => {
    let description =''
    if (availailtiy) {
      if (availailtiy === 'all day'){
        description= "Available " +availailtiy
      }else if (availailtiy === 'booked'  && booked) {
        description= "Booked for "+ formatTime(booked)
      } else {
        description= "Available for "+ formatTime(availailtiy)
      }
    }
    return description
  }

  const getTrafficScore =() => {
    if(trafficScore === '--' || trafficScore === null  ){
      return "No traffic today"
    }else {
       return trafficScore+" traffic"
    }
  }
 
  return (
    <div className={styles.pod}>
        <div className={styles.iconTitle}>
            <FiberManualRecordIcon fontSize={'inherit'}  className={wellnessScore > .66 ? styles.red : (wellnessScore > .33 ? styles.yellow : styles.green)}/>
            <span>{podName}</span>
        </div>
         <div className={styles.description}>
               {getStatus()}
          </div>
        
          <div className={styles.description}>
             { getTrafficScore()}
          </div>
        
        {!props.isAdmin && false && // not for beta
          <ToggleButton
            value="check"
            selected={true}
            className={classNames( styles.button , buttonHolding ? styles.holding : styles.holdme )}
            onChange={() => setButtonHolding(!buttonHolding)}
          >
            {buttonHolding ? "Holding": "Hold for me" }   
          </ToggleButton>
        }
    </div>
  )
}

export default PodsDetails

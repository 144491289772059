import { baseMxRenewURL } from './config.default'

export const baseURL = 'kepler.mersive.com'
export const baseBackendURL = 'kepler-backend.mersive.com'
export const baseAuthURL = 'kepler-auth-svc.mersive.com'

const config = {
  googleAnalytics: 'UA-16236886-4',
  hotjar: {
    id: 780960,
    snippetVersion: 6
  },
  airbrake: {
    projectId: 173994,
    projectKey: 'f1ef26325226d6ccde8677090db23844'
  }
}

export const isLocalhost = (_hostname: string, _port: string): boolean => false

export const webSocketURL = (_hostname: string, _port: string): string =>
  'wss://dev-' + baseBackendURL + '/consume'

export const getAuthURL = (_hostname: string, _port: string): string =>
  'https://dev-' + baseAuthURL

export const getMxRenewURL = (_hostname: string, _port: string): string =>
  'https://dev-' + baseMxRenewURL

export const isProduction = (): boolean => false

export const ldClientId = '5d1105b1d7bc9707683c4226'

export const graphQLServerUrl = 'https://dev-kepler-backend.mersive.com/graphql'

export const imageServerUrl = 'https://dev-kepler-backend.mersive.com/images'

export const hasuraServerUrl = 'https://dev-hasura.mersive.com/v1/graphql'

export const hasuraServerWs = 'wss://dev-hasura.mersive.com/v1/graphql'

export default config

import React from 'react'
import 'index.css'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import {
  ApolloClient,
  ApolloLink,
  ServerError,
  HttpLink,
  ApolloProvider as ApolloHooksProvider
} from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { createHistory } from 'shared/core/history'
import { graphqlServerUrl } from 'shared/core/config'
import WellnessApp from './WellnessApp'

createHistory()

const rootElement = document.getElementById('root') as HTMLElement

const urlParams = new URLSearchParams(window.location.search)
const lat = urlParams.get('lat')
const long =urlParams.get('long')
const id = urlParams.get('id')

const getHeaders = () => ({
   "x-device-id":   id , //'88a7e7da-47d6-49bf-89a2-fa12dafb23a6', // dev mersive 
   "x-device-lat":  lat,	 
   "x-device-lng":  long,
})

const serverMiddleware = new ApolloLink((operation, forward: any) => {
    operation.setContext({ headers: getHeaders() })
    return forward(operation)
})

const serverLink = new HttpLink({
  uri:   graphqlServerUrl
})

const requestLink = serverMiddleware.concat(serverLink)

const errorLink = onError(args => {
  const { graphQLErrors, networkError } = args
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`
      )
    })
  }
  if (networkError) {
    if ((networkError as ServerError)?.response?.status === 401) {
      console.log("401 - ", (networkError as any)?.bodyText )
    }
  }
}
)

const cache = new InMemoryCache({})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink , requestLink]),
  cache,
})

const components = () => (
  <AppContainer>
    <ApolloHooksProvider client={client}>
      <WellnessApp />
    </ApolloHooksProvider>
  </AppContainer>
)

render(components(), rootElement)

// if (module.hot) {
//   module.hot.accept('./App', () => render(components(), rootElement))
// }

export const baseURL = 'kepler.mersive.com'
export const baseBackendURL = 'kepler-backend.mersive.com'
export const baseAuthURL = 'kepler-auth-svc.mersive.com'
export const baseMxRenewURL = 'kepler-backend.mersive.com'

const config = {
  googleAnalytics: 'UA-16236886-4',
  hotjar: {
    id: 780960,
    snippetVersion: 6
  },
  airbrake: {
    projectId: 173994,
    projectKey: 'f1ef26325226d6ccde8677090db23844'
  }
}

const getEnv = (hostname: string): any => {
  const re = new RegExp('.*(?=' + baseURL + ')')
  const m = hostname.match(re)
  let env = m && m[0] ? m[0].replace(/-/, '') : 'prod'
  if (hostname.includes('test')) {
    env = 'staging'
  }
  let urlPrefix = env + '-'
  if (env === 'prod') {
    urlPrefix = ''
  } else if (env === 'dev') {
    // SAAS-500: point firebase dev client at staging apis
    //  urlPrefix = 'staging-'
    // SAAS-516: temporarily point at dev- to test delete user
    urlPrefix = 'dev-'
  }
  return {
    env,
    urlPrefix
  }
}

export const isLocalhost = (hostname: string, port: string): boolean =>
  hostname === 'localhost' && (port === '3000' || port === '8443')

export const webSocketURL = (hostname: string, port: string): string => {
  // in development, websocket port is 8443 but front end is served on localhost:3000
  if (isLocalhost(hostname, port)) {
    return 'wss://localhost:8443/consume'
  }

  const env = getEnv(hostname)
  return 'wss://' + env.urlPrefix + baseBackendURL + '/consume'
}

export const getAuthURL = (hostname: string, port: string): string => {
  if (isLocalhost(hostname, port)) {
    return 'https://localhost:8443/auth'
  }

  const env = getEnv(hostname)
  return 'https://' + env.urlPrefix + baseAuthURL
}

export const getMxRenewURL = (hostname: string, port: string): string => {
  if (isLocalhost(hostname, port)) {
    return 'https://localhost:8443/mxRenew' // likely to change
  }

  const env = getEnv(hostname)
  return 'https://' + env.urlPrefix + baseMxRenewURL
}

export const isProduction = (): boolean => window.location.hostname === baseURL
export const getLdClientId = () => {
  const { env } = getEnv(window.location.hostname)
  if (env === 'prod') {
    return '5d1104e5d7bc9707683c4216'
  } else if (env === 'staging') {
    return '5d11057dd7bc9707683c421e'
  }
  return '5d1105b1d7bc9707683c4226'
}

export default config

export const getGraphQLServerUrl = () => {
  const { urlPrefix } = getEnv(window.location.hostname)
  return `https://${urlPrefix}kepler-backend.mersive.com/graphql`
}

export const getHasuraServerWs = () => {
  const { urlPrefix } = getEnv(window.location.hostname)
  return `wss://${urlPrefix}hasura.mersive.com/v1/graphql`
}

export const getHasuraServerUrl = () => {
  const { urlPrefix } = getEnv(window.location.hostname)
  return `https://${urlPrefix}hasura.mersive.com/v1/graphql`
}

export const getImageServerUrl = () => {
  const { urlPrefix } = getEnv(window.location.hostname)
  return `https://${urlPrefix}kepler-backend.mersive.com/images`
}

import React from "react"
import MapIcon from '@material-ui/icons/Map'
import { Drawer } from '@material-ui/core'
import styles from './WellnessDrawer.module.scss'

type Props = {
    isOpen: boolean
    closeDrawer: () => void
    children: React.ReactNode
  }

function WellnessDrawer({
    isOpen,
    closeDrawer,
    children
}: Props){

    return (
        <Drawer 
            anchor={'left'} 
            open={isOpen} 
            onClose={() => closeDrawer()}
            className={styles.drawer}
          >
            <div className={styles.children}>{children}</div>
            <button className={styles.viewMapButton} onClick={() => closeDrawer()} >
                <div> 
                    <MapIcon /> <p>View Map</p> 
                </div> 
            </button>
          </Drawer>

    )
}
export default WellnessDrawer

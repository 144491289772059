import React, { useEffect, useState } from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'
import {divIcon , LatLng } from 'leaflet'
import styles from './Map.module.scss'
import 'leaflet/dist/leaflet.css'
import { renderToString } from 'react-dom/server'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

type Props = {
  children: React.ReactNode
  userLocation: LatLng | undefined
  isAdmin: boolean
  zoom: number
} 

function FullPageMap(props: Props) {
  const [center, setCenter] = useState(new LatLng(0,0))
  const ConditionalWrap = ({ condition, wrap, children }) => (
    condition ? wrap (children) : children
  )

  const getMarker = () => {
    const size = props.zoom * 9.5
    const zoomStyles = {
       borderWidth:  size,
       left: -1 * size, 
       top: -1 * size
    }
    return (
      <ConditionalWrap 
                    condition={props.zoom > 18 }
                    wrap={children => (<div className={styles.blueRadius} style={zoomStyles}> {children} </div>  )}
                  >
                    <FiberManualRecordIcon className={styles.blueDot} />  
                  {/* {props.zoom > 19 && props.userLocation &&
                    <div style={{marginTop: 20}}>Lat: {props.userLocation.lat} Lng: {props.userLocation.lng}</div>
                  } */}
      </ConditionalWrap> 
    )
  }

  useEffect(() => {
    if (props.userLocation ){
    setCenter(props.isAdmin ? new LatLng(0, -20) : props.userLocation)
    }
  }, [])
  
  return (
        <Map
          className={props.isAdmin ? styles.map : styles.adminMap}
          center={center}
          zoom={props.isAdmin ? 3 : 13}
          maxZoom={21}
          minZoom={2}
          zoomControl={false}
        >
          <TileLayer
            url="//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution={`&copy; <a href=https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>`}
          />
          {props.userLocation &&  
              <Marker 
                position={props.userLocation}
                icon={ divIcon({ html: renderToString(getMarker())}) }
                bounds={false}
              />
          }
          {props.children} 
        </Map>
    )
}
export default FullPageMap

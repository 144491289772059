import React, { useEffect } from 'react'
import { useLeaflet, Marker } from 'react-leaflet'
import { LatLng, LatLngBounds, divIcon } from 'leaflet'
import Clusters from './Clusters/Clusters'
import { BuildingDetails } from '../WellnessComponents/BuildingDetails/BuildingDetailsTypes'
import { PodDetails } from '../WellnessComponents/PodDetails/PodDetailsTypes'
import styles from './MapElements.module.scss'
import { renderToString } from 'react-dom/server'

type Props = {
  setZoom: (number) => void
  setCardID: (string) => void
  setMapView: (LatLngBounds) => void
  mapType: 'Buildings' | 'Pods'
  zoom: number
  mapView: LatLngBounds | undefined
  cardID: string
  floor: number
  setFloor: (number) => void
  buildingsData: BuildingDetails[] | undefined
  allPods: PodDetails[] | undefined
  userLocation: LatLng | undefined
  zoomToUserLoc: boolean
  isAdmin: boolean
}

function MapElements(props: Props) {

  const { map } = useLeaflet()

  const getBoundingBox = () => {
    if (map) {
      return map.getBounds() as LatLngBounds || undefined
    }
    else {
      return undefined
    }
  }

  useEffect(() => {
    map?.addEventListener('moveend', () => {
      const newBounds = getBoundingBox()
      if (props.mapView !== newBounds && newBounds !== undefined) {
        props.setMapView(newBounds)
      }
    })

    map?.addEventListener('zoomend', () => {
      props.setZoom(map?.getZoom() ?? props.zoom)
    })
  }, [])

  const getMarker = (place: BuildingDetails) => (
    <div className={styles.building} >
      <div className={styles.name}> {place.buildingNickname || place.buildingName}</div>
      { props.userLocation && place.lat && place.lng &&
        <div className={styles.dist} >{(props.userLocation.distanceTo(new LatLng(place.lat, place.lng)) * 0.00062137).toFixed(2) + ' miles away'}</div>
      }
    </div>
  )

  useEffect(() => {
    if (props.userLocation && !props.isAdmin) {
      map?.flyTo(props.userLocation, 17)
    }
  }, [props.zoomToUserLoc])

  return (
    <div>
      <Clusters
        mapType={props.mapType}
        zoom={props.zoom}
        setCardID={(e: string) => props.setCardID(e)}
        cardID={props.cardID}
        allPods={props.allPods}
        floor={props.floor}
      />
      {props.zoom > 12 && props.zoom < 17 && props.buildingsData?.map(place => 
         { place.lat && place.lng && 
          (<Marker
              position={new LatLng(place.lat, place.lng)}
              icon={divIcon({ html: renderToString(getMarker(place)) })}
            />)
         })
      }
    </div>
  )
}
export default MapElements

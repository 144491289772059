// default config
import defaultConfig, {
  baseAuthURL as defaultBaseAuthURL,
  baseBackendURL as defaultBaseBackendURL,
  baseURL as defaultBaseUrl,
  getAuthURL as defaultGetAuthURL,
  getGraphQLServerUrl,
  getHasuraServerUrl,
  getImageServerUrl,
  getLdClientId as getDefaultLdClient,
  getMxRenewURL as defaultGetMxRenewURL,
  isLocalhost as defaultIsLocalhost,
  isProduction as defaultIsProduction,
  webSocketURL as defaultWebSocketURL,
  getHasuraServerWs
} from './config.default'
// dev.dev config
import devDevConfig, {
  baseAuthURL as devDevBaseAuthURL,
  baseBackendURL as devDevBaseBackendURL,
  baseURL as devDevBaseUrl,
  getAuthURL as devDevGetAuthURL,
  getMxRenewURL as devDevGetMxRenewURL,
  graphQLServerUrl as devDevGraphQLServerUrl,
  imageServerUrl as devDevImageServerUrl,
  isLocalhost as devDevIsLocalhost,
  isProduction as devDevIsProduction,
  ldClientId as devDevLdClientId,
  webSocketURL as devDevWebSocketURL,
  hasuraServerUrl as devDevHasuraServerUrl,
  hasuraServerWs as devDevHasuraServerWs
} from './config.dev.dev'
// dev.local config
import devLocalConfig, {
  baseAuthURL as devLocalBaseAuthURL,
  baseBackendURL as devLocalBaseBackendURL,
  baseURL as devLocalBaseUrl,
  getAuthURL as devLocalGetAuthURL,
  getMxRenewURL as devLocalGetMxRenewURL,
  isLocalhost as devLocalIsLocalhost,
  isProduction as devLocalIsProduction,
  webSocketURL as devLocalWebSocketURL,
  hasuraServerUrl as devLocalHasuraServerUrl,
  hasuraServerWs as devLocalHasuraServerWs
} from './config.dev.local'
// dev.prod config
import devProdConfig, {
  baseAuthURL as devProdBaseAuthURL,
  baseBackendURL as devProdBaseBackendURL,
  baseURL as devProdBaseUrl,
  getAuthURL as devProdGetAuthURL,
  getMxRenewURL as devProdGetMxRenewURL,
  graphQLServerUrl as devProdGraphQLServerUrl,
  imageServerUrl as devProdImageServerUrl,
  isLocalhost as devProdIsLocalhost,
  isProduction as devProdIsProduction,
  ldClientId as devProdLdClientId,
  webSocketURL as devProdWebSocketURL,
  hasuraServerUrl as devProdHasuraServerUrl,
  hasuraServerWs as devProdHasuraServerWs
} from './config.dev.prod'
// dev.staging config
import devStagingConfig, {
  baseAuthURL as devStagingBaseAuthURL,
  baseBackendURL as devStagingBaseBackendURL,
  baseURL as devStagingBaseUrl,
  getAuthURL as devStagingGetAuthURL,
  getMxRenewURL as devStagingGetMxRenewURL,
  graphQLServerUrl as devStagingGraphQLServerUrl,
  imageServerUrl as devStagingImageServerUrl,
  isLocalhost as devStagingIsLocalhost,
  isProduction as devStagingIsProduction,
  ldClientId as devStagingLdClientId,
  webSocketURL as devStagingWebSocketURL,
  hasuraServerUrl as devStagingHasuraServerUrl,
  hasuraServerWs as devStagingHasuraServerWs
} from './config.dev.staging'
// shared config

const packageName = process.env.REACT_APP_NAME
const packageVersion = process.env.REACT_APP_VERSION

type ConfigEnv =
  | 'default'
  | 'dev.local'
  | 'dev.dev'
  | 'dev.staging'
  | 'dev.prod'
  | 'dev.test'

let configEnv: ConfigEnv = 'default'

if (process.env.REACT_APP_SERVER_ENV) {
  configEnv = process.env.REACT_APP_SERVER_ENV as ConfigEnv
}

// defaults: interpret config from hostname
let config = defaultConfig
let baseURL: string = defaultBaseUrl
let baseBackendURL: string = defaultBaseBackendURL
let baseAuthURL: string = defaultBaseAuthURL
let isLocalhost: Function = defaultIsLocalhost
let webSocketURL: Function = defaultWebSocketURL
let getAuthURL: Function = defaultGetAuthURL
let getMxRenewURL: Function = defaultGetMxRenewURL
let isProduction: Function = defaultIsProduction
let ldClientId: string = getDefaultLdClient()
let graphqlServerUrl: string = getGraphQLServerUrl()
let hasuraServerUrl: string = getHasuraServerUrl()
let hasuraServerWs: string = getHasuraServerWs()
let imageServerUrl: string = getImageServerUrl()

// override if we have an explicit configEnv
switch (configEnv) {
  case 'dev.local':
    // run a local dev client against a local server stack
    config = devLocalConfig
    baseURL = devLocalBaseUrl
    baseBackendURL = devLocalBaseBackendURL
    baseAuthURL = devLocalBaseAuthURL
    isLocalhost = devLocalIsLocalhost
    webSocketURL = devLocalWebSocketURL
    getAuthURL = devLocalGetAuthURL
    getMxRenewURL = devLocalGetMxRenewURL
    isProduction = devLocalIsProduction
    hasuraServerUrl = devLocalHasuraServerUrl
    hasuraServerWs = devLocalHasuraServerWs
    break
  case 'dev.dev':
    // run a local dev client against the staging server stack
    config = devDevConfig
    baseURL = devDevBaseUrl
    baseBackendURL = devDevBaseBackendURL
    baseAuthURL = devDevBaseAuthURL
    isLocalhost = devDevIsLocalhost
    webSocketURL = devDevWebSocketURL
    getAuthURL = devDevGetAuthURL
    getMxRenewURL = devDevGetMxRenewURL
    isProduction = devDevIsProduction
    ldClientId = devDevLdClientId
    graphqlServerUrl = devDevGraphQLServerUrl
    imageServerUrl = devDevImageServerUrl
    hasuraServerUrl = devDevHasuraServerUrl
    hasuraServerWs = devDevHasuraServerWs
    break
  case 'dev.staging':
    // run a local dev client against the staging server stack
    config = devStagingConfig
    baseURL = devStagingBaseUrl
    baseBackendURL = devStagingBaseBackendURL
    baseAuthURL = devStagingBaseAuthURL
    isLocalhost = devStagingIsLocalhost
    webSocketURL = devStagingWebSocketURL
    getAuthURL = devStagingGetAuthURL
    getMxRenewURL = devStagingGetMxRenewURL
    isProduction = devStagingIsProduction
    ldClientId = devStagingLdClientId
    graphqlServerUrl = devStagingGraphQLServerUrl
    imageServerUrl = devStagingImageServerUrl
    hasuraServerUrl = devStagingHasuraServerUrl
    hasuraServerWs = devStagingHasuraServerWs
    break
  case 'dev.prod':
    // run a local dev client against the production server stack
    config = devProdConfig
    baseURL = devProdBaseUrl
    baseBackendURL = devProdBaseBackendURL
    baseAuthURL = devProdBaseAuthURL
    isLocalhost = devProdIsLocalhost
    webSocketURL = devProdWebSocketURL
    getAuthURL = devProdGetAuthURL
    getMxRenewURL = devProdGetMxRenewURL
    isProduction = devProdIsProduction
    ldClientId = devProdLdClientId
    graphqlServerUrl = devProdGraphQLServerUrl
    imageServerUrl = devProdImageServerUrl
    hasuraServerUrl = devProdHasuraServerUrl
    hasuraServerWs = devProdHasuraServerWs
    break
  case 'default':
  default:
  // keep defaults: interpret config from hostname
}

export {
  baseURL,
  baseBackendURL,
  baseAuthURL,
  isLocalhost,
  webSocketURL,
  getAuthURL,
  getMxRenewURL,
  isProduction,
  packageName,
  packageVersion,
  ldClientId,
  graphqlServerUrl,
  hasuraServerUrl,
  hasuraServerWs,
  imageServerUrl
}

export default config

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The Config broken up into Settings Groups as shown in DisplayConfiguration */
  Config: any;
  /** The raw JSON config that solstice expects */
  RawConfig: any;
};

export type Query = {
  __typename?: 'Query';
  displays?: Maybe<Displays>;
  wellnessDisplays?: Maybe<Array<Maybe<WellnessDisplay>>>;
  wellnessAddresses?: Maybe<Array<Maybe<Address>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoryMetricsByDate?: Maybe<Array<Maybe<CategoryMetricsByDate>>>;
  metricsByDate?: Maybe<Array<Maybe<MetricsByDate>>>;
  recentAlerts?: Maybe<Alert>;
  versions?: Maybe<Array<Maybe<Product>>>;
  analyticCounts?: Maybe<AnalyticCounts>;
  liveAnalyticCounts?: Maybe<LiveAnalyticCounts>;
  userDevices?: Maybe<Array<Maybe<ClientPlatform>>>;
  templates?: Maybe<Array<Maybe<Template>>>;
  templateCompatibility?: Maybe<Array<Maybe<TemplateCompatibility>>>;
  uniqueSettingsCompatibility?: Maybe<UniqueSettingsCompatibility>;
  settingsGroups?: Maybe<Array<Maybe<SettingsGroup>>>;
  displayCompatibilityAggregate?: Maybe<DisplayCompatibilityAggregate>;
  display?: Maybe<Display>;
  activeLearningRooms?: Maybe<Array<Maybe<ActiveLearningRoom>>>;
  timeZones?: Maybe<Array<TimeZone>>;
  languages?: Maybe<Array<Language>>;
  currentScheduledTasks: Array<Maybe<ScheduledTask>>;
  historicalScheduledTasks: Array<Maybe<ScheduledTask>>;
};


export type QueryDisplaysArgs = {
  options?: Maybe<DisplaySearchOptions>;
};


export type QueryCategoryMetricsByDateArgs = {
  options: CategoryMetricSearchOptions;
};


export type QueryMetricsByDateArgs = {
  options: MetricSearchOptions;
};


export type QueryRecentAlertsArgs = {
  options: RecentAlertsOptions;
};


export type QueryVersionsArgs = {
  options?: Maybe<VersionsOptions>;
};


export type QueryAnalyticCountsArgs = {
  options: AnalyticCountsOptions;
};


export type QueryUserDevicesArgs = {
  options: AnalyticCountsOptions;
};


export type QueryTemplatesArgs = {
  options?: Maybe<TemplateSearchOptions>;
};


export type QueryTemplateCompatibilityArgs = {
  options?: Maybe<TemplateCompatibilityOptions>;
};


export type QueryUniqueSettingsCompatibilityArgs = {
  options?: Maybe<UniqueSettingsCompatibilityOptions>;
};


export type QuerySettingsGroupsArgs = {
  options?: Maybe<SettingsGroupSearchOptions>;
};


export type QueryDisplayCompatibilityAggregateArgs = {
  options?: Maybe<DisplaySearchOptions>;
};


export type QueryDisplayArgs = {
  id: Scalars['String'];
};


export type QueryCurrentScheduledTasksArgs = {
  options: ScheduledTaskSearchOptions;
};


export type QueryHistoricalScheduledTasksArgs = {
  options: ScheduledTaskSearchOptions;
};

export type DisplaySearchOptions = {
  assignedTemplateId?: Maybe<Scalars['String']>;
  confirmedTemplateId?: Maybe<Scalars['String']>;
  displayIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  excludeDisplayIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isOnline?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<CategorySearch>>>;
  records?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByDisplay>;
  metrics?: Maybe<DisplayMetricSearchOptions>;
  /** Returns displays that are eligible for Kepler Management */
  isManageable?: Maybe<Scalars['Boolean']>;
  inClassicSplash?: Maybe<Scalars['Boolean']>;
  activeLearning?: Maybe<DisplayActiveLearningSearchOptions>;
  managementStatus?: Maybe<DisplayManagementStatusSearchOptions>;
  hardware?: Maybe<Array<Maybe<Hardware>>>;
  location?: Maybe<RadiusLocation>;
};

export type CategorySearch = {
  id: Scalars['String'];
  includeUnassigned?: Maybe<Scalars['Boolean']>;
  optionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderByDisplay = {
  field?: Maybe<DisplayOrderableField>;
  category?: Maybe<Scalars['String']>;
  settingsGroupType?: Maybe<SettingsGroupType>;
  direction: Direction;
};

export enum DisplayOrderableField {
  Id = 'ID',
  Name = 'NAME',
  Version = 'VERSION',
  IsOnline = 'IS_ONLINE',
  IsManageable = 'IS_MANAGEABLE',
  Posts = 'POSTS',
  Meetings = 'MEETINGS',
  MinutesInUse = 'MINUTES_IN_USE',
  MultiRoomSessions = 'MULTI_ROOM_SESSIONS',
  Users = 'USERS',
  Subscription = 'SUBSCRIPTION',
  AverageMeetingDuration = 'AVERAGE_MEETING_DURATION',
  AverageMeetingRating = 'AVERAGE_MEETING_RATING',
  ContentItemsPerMeeting = 'CONTENT_ITEMS_PER_MEETING',
  MaxOccupancy = 'MAX_OCCUPANCY',
  AverageHourlyOccupancy = 'AVERAGE_HOURLY_OCCUPANCY',
  ActiveLearningExpires = 'ACTIVE_LEARNING_EXPIRES',
  ActiveLearningStatus = 'ACTIVE_LEARNING_STATUS',
  ActiveLearningRoomName = 'ACTIVE_LEARNING_ROOM_NAME',
  ConfSessions = 'CONF_SESSIONS',
  Cam1 = 'CAM1',
  Cam2 = 'CAM2'
}

export enum SettingsGroupType {
  Advanced = 'ADVANCED',
  Calendar = 'CALENDAR',
  DigitalSignage = 'DIGITAL_SIGNAGE',
  Discovery = 'DISCOVERY',
  Ethernet = 'ETHERNET',
  Features = 'FEATURES',
  JunkDrawer = 'JUNK_DRAWER',
  MessageCenter = 'MESSAGE_CENTER',
  PowerManagement = 'POWER_MANAGEMENT',
  Proxy = 'PROXY',
  Security = 'SECURITY',
  TimeLocale = 'TIME_LOCALE',
  UniqueSettings = 'UNIQUE_SETTINGS',
  WelcomeScreen = 'WELCOME_SCREEN',
  Wifi = 'WIFI',
  RoomIntelligence = 'ROOM_INTELLIGENCE'
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DisplayMetricSearchOptions = {
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};

export type DisplayActiveLearningSearchOptions = {
  statuses?: Maybe<Array<Maybe<ActiveLearningStatus>>>;
};

export enum ActiveLearningStatus {
  UnsupportedSolstice = 'UNSUPPORTED_SOLSTICE',
  UnsupportedFirmware = 'UNSUPPORTED_FIRMWARE',
  Current = 'CURRENT',
  ExpiringSoon = 'EXPIRING_SOON',
  Expired = 'EXPIRED',
  TrialActive = 'TRIAL_ACTIVE',
  TrialExpired = 'TRIAL_EXPIRED',
  ReadyToEnable = 'READY_TO_ENABLE'
}

export type DisplayManagementStatusSearchOptions = {
  statuses?: Maybe<Array<Maybe<ManagementStatus>>>;
};

export enum ManagementStatus {
  Offline = 'Offline',
  Online = 'Online',
  OfflinePending = 'OfflinePending',
  Unsupported = 'Unsupported',
  NotYetDeployed = 'NotYetDeployed'
}

export enum Hardware {
  Gen2 = 'GEN2',
  Gen2I = 'GEN2I',
  Gen3 = 'GEN3'
}

export type RadiusLocation = {
  center?: Maybe<LocationInput>;
  radiusKm?: Maybe<Scalars['Int']>;
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Displays = {
  __typename?: 'Displays';
  page?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Display>>>;
};

export type Display = {
  __typename?: 'Display';
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Scalars['String'];
  versions: DisplayVersions;
  isOnline: Scalars['Boolean'];
  /** Is Eligible for Kepler Management */
  isManageable: Scalars['Boolean'];
  managementStatus: ManagementStatus;
  categories?: Maybe<Array<Maybe<AppliedCategory>>>;
  hasPendingChanges: Scalars['Boolean'];
  assignedTemplate?: Maybe<AssignedTemplate>;
  assignedTemplates: Array<Maybe<AssignedTemplate>>;
  metrics?: Maybe<DisplayMetrics>;
  subscription: Subscription;
  displayCam?: Maybe<DisplayCam>;
  activeLearning?: Maybe<ActiveLearning>;
  macAddressesPrimary?: Maybe<Scalars['String']>;
  macAddressesWifi?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};


export type DisplayAssignedTemplateArgs = {
  settingsGroupType: SettingsGroupType;
};

export type DisplayVersions = {
  __typename?: 'DisplayVersions';
  hardware?: Maybe<Scalars['String']>;
  software?: Maybe<Scalars['String']>;
  lms?: Maybe<Scalars['String']>;
};

export type AppliedCategory = {
  __typename?: 'AppliedCategory';
  id: Scalars['ID'];
  categoryId: Scalars['String'];
  name: Scalars['String'];
  option?: Maybe<Option>;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Scalars['String'];
  category?: Maybe<Category>;
  appliedDisplayCount: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Scalars['String'];
  options?: Maybe<Array<Maybe<Option>>>;
};

export type AssignedTemplate = {
  __typename?: 'AssignedTemplate';
  template?: Maybe<Template>;
  settingsGroup?: Maybe<SettingsGroup>;
  hasPendingChanges?: Maybe<Scalars['Boolean']>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Scalars['String'];
  settingsGroup: SettingsGroup;
  isCustom: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  assignedDisplays: AssignedDisplays;
  revision: Revision;
};

export type SettingsGroup = {
  __typename?: 'SettingsGroup';
  name: Scalars['String'];
  type: SettingsGroupType;
  hidden: Scalars['Boolean'];
  templates?: Maybe<Array<Maybe<Template>>>;
  settings?: Maybe<Array<Maybe<ConfigurationSetting>>>;
};

export type ConfigurationSetting = {
  __typename?: 'ConfigurationSetting';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  compatibility?: Maybe<Compatibility>;
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export type Compatibility = {
  __typename?: 'Compatibility';
  minSoftware: Scalars['String'];
  maxSoftware?: Maybe<Scalars['String']>;
  minHardware: Scalars['String'];
  maxHardware?: Maybe<Scalars['String']>;
  minLms: Scalars['String'];
  maxLms?: Maybe<Scalars['String']>;
  hardRequirement: Scalars['Boolean'];
  softRequirement: Scalars['Boolean'];
  subscriptionRequired: Scalars['Boolean'];
};

export type AssignedDisplays = {
  __typename?: 'AssignedDisplays';
  compatibilityAggregate?: Maybe<DisplayCompatibilityAggregate>;
  totalRecords: Scalars['Int'];
};

export type DisplayCompatibilityAggregate = {
  __typename?: 'DisplayCompatibilityAggregate';
  minSoftware: Scalars['String'];
  minHardware: Scalars['String'];
  minLms: Scalars['String'];
};

export type Revision = {
  __typename?: 'Revision';
  id: Scalars['ID'];
  /** Our representation of the Solstice Configuration broken up into chunks by Settings Group */
  configuration?: Maybe<Scalars['Config']>;
  /** The raw config object that the Solstice API expects */
  rawConfiguration?: Maybe<Scalars['RawConfig']>;
};



export type DisplayMetrics = {
  __typename?: 'DisplayMetrics';
  totalPosts: Scalars['Int'];
  totalMeetings: Scalars['Int'];
  totalMinutesInUse: Scalars['Float'];
  totalUsers: Scalars['Int'];
  totalMaxOccupancy?: Maybe<Scalars['Int']>;
  totalAverageMeetingDuration: Scalars['Float'];
  totalAverageMeetingRating: Scalars['Float'];
  totalContentPerMeeting: Scalars['Float'];
  totalAverageHourlyOccupancy: Scalars['Float'];
  totalConfSessions: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  status: SubscriptionStatus;
  expirationDate?: Maybe<Scalars['String']>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export type DisplayCam = {
  __typename?: 'DisplayCam';
  cam1?: Maybe<Scalars['String']>;
  cam2?: Maybe<Scalars['String']>;
};

export type ActiveLearning = {
  __typename?: 'ActiveLearning';
  rooms: Array<ActiveLearningRoom>;
  trial?: Maybe<ActiveLearningTrial>;
  status: Scalars['String'];
  licenseEndDate?: Maybe<Scalars['String']>;
};

export type ActiveLearningRoom = {
  __typename?: 'ActiveLearningRoom';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ActiveLearningTrial = {
  __typename?: 'ActiveLearningTrial';
  endDate?: Maybe<Scalars['String']>;
  isEligible: Scalars['Boolean'];
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  address_id?: Maybe<Scalars['Int']>;
  floor?: Maybe<Scalars['Int']>;
};

export type WellnessDisplay = {
  __typename?: 'WellnessDisplay';
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  address_id?: Maybe<Scalars['Int']>;
  floor?: Maybe<Scalars['Int']>;
  cameraOccupancy?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['String']>;
  booked?: Maybe<Scalars['String']>;
  wellnessScore?: Maybe<Scalars['Float']>;
  trafficScore?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  address_full?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  state_province?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  num_of_displays?: Maybe<Scalars['Int']>;
  percent_booked?: Maybe<Scalars['Float']>;
  wellnessScore?: Maybe<Scalars['Float']>;
  trafficScore?: Maybe<Scalars['String']>;
};

export type CategoryMetricSearchOptions = {
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  primaryCategoryId: Scalars['String'];
  categories?: Maybe<Array<Maybe<CategorySearch>>>;
  metricType: MetricType;
  timeInterval: TimeInterval;
};

export enum MetricType {
  Meetings = 'MEETINGS',
  Users = 'USERS',
  MinutesInUse = 'MINUTES_IN_USE',
  Posts = 'POSTS',
  AverageMeetingDuration = 'AVERAGE_MEETING_DURATION',
  ContentItemsPerMeeting = 'CONTENT_ITEMS_PER_MEETING',
  MaxOccupancy = 'MAX_OCCUPANCY',
  AverageHourlyOccupancy = 'AVERAGE_HOURLY_OCCUPANCY',
  AverageMeetingRating = 'AVERAGE_MEETING_RATING'
}

export enum TimeInterval {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH'
}

export type CategoryMetricsByDate = {
  __typename?: 'CategoryMetricsByDate';
  date: Scalars['String'];
  options?: Maybe<Array<Maybe<TotalByOption>>>;
};

export type TotalByOption = {
  __typename?: 'TotalByOption';
  optionId: Scalars['String'];
  option?: Maybe<Option>;
  total: Scalars['Float'];
};

export type MetricSearchOptions = {
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  metricTypes: Array<MetricType>;
  timeInterval: TimeInterval;
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
};

export type MetricsByDate = {
  __typename?: 'MetricsByDate';
  date: Scalars['String'];
  metrics: Array<Maybe<TotalByMetric>>;
};

export type TotalByMetric = {
  __typename?: 'TotalByMetric';
  name: MetricType;
  total: Scalars['Float'];
};

export type RecentAlertsOptions = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
  timeInterval: TimeInterval;
};

export type Alert = {
  __typename?: 'Alert';
  totalDisplays: Scalars['Int'];
  totalAlerts: Scalars['Int'];
  alerts: Array<Maybe<RecentAlert>>;
};

export type RecentAlert = {
  __typename?: 'RecentAlert';
  date: Scalars['String'];
  totalAlerts: Scalars['Int'];
};

export type VersionsOptions = {
  type?: Maybe<ProductType>;
  edition?: Maybe<ProductEdition>;
  platform?: Maybe<ProductPlatform>;
};

export enum ProductType {
  SolsticeServer = 'SOLSTICE_SERVER',
  SolsticeDashboard = 'SOLSTICE_DASHBOARD',
  SolsticeAndroidImage = 'SOLSTICE_ANDROID_IMAGE'
}

export enum ProductEdition {
  Sge = 'SGE',
  Unlimited = 'UNLIMITED',
  Base = 'BASE'
}

export enum ProductPlatform {
  Android = 'ANDROID',
  Windows = 'WINDOWS',
  PodGen2I = 'POD_GEN2I',
  PodGen3 = 'POD_GEN3',
  Pod_3 = 'POD_3'
}

export type Product = {
  __typename?: 'Product';
  type?: Maybe<Scalars['String']>;
  edition?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  releases?: Maybe<Array<Maybe<Release>>>;
};

export type Release = {
  __typename?: 'Release';
  version?: Maybe<Scalars['String']>;
  build?: Maybe<Scalars['String']>;
  customerFacingVersion?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  androidVersionCode?: Maybe<Scalars['String']>;
  minimumAndroidVersionCode?: Maybe<Scalars['String']>;
  minimumDashboardVersion?: Maybe<Scalars['String']>;
  minimumServerVersion?: Maybe<Scalars['String']>;
  relNotes?: Maybe<Scalars['String']>;
  download?: Maybe<Scalars['String']>;
};

export type AnalyticCountsOptions = {
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type AnalyticCounts = {
  __typename?: 'AnalyticCounts';
  medianMeeting: Scalars['Int'];
  busiestHour: Scalars['Int'];
  uniqueUsers: Scalars['Int'];
};

export type LiveAnalyticCounts = {
  __typename?: 'LiveAnalyticCounts';
  activeMeetings: Scalars['Int'];
};

export type ClientPlatform = {
  __typename?: 'ClientPlatform';
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type TemplateSearchOptions = {
  isCustom?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  settingsGroupType?: Maybe<SettingsGroupType>;
  templateIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type TemplateCompatibilityOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export type TemplateCompatibility = {
  __typename?: 'TemplateCompatibility';
  templateId: Scalars['String'];
  template: Template;
  isCompatible: Scalars['Boolean'];
  isHardRequirement?: Maybe<Scalars['Boolean']>;
  incompatibleReason?: Maybe<Scalars['String']>;
};

export type UniqueSettingsCompatibilityOptions = {
  templateId: Scalars['ID'];
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
};

export type UniqueSettingsCompatibility = {
  __typename?: 'UniqueSettingsCompatibility';
  incompatibleDisplays?: Maybe<Array<Maybe<Display>>>;
  fieldValidation?: Maybe<UniqueSettingsValidation>;
};

export type UniqueSettingsValidation = {
  __typename?: 'UniqueSettingsValidation';
  ETHERNET?: Maybe<EthernetUniqueSettingsValidation>;
  WELCOME_SCREEN?: Maybe<WelcomeScreenUniqueSettingsValidation>;
  CALENDAR?: Maybe<CalendarUniqueSettingsValidation>;
  WIFI?: Maybe<WifiUniqueSettingsValidation>;
};

export type EthernetUniqueSettingsValidation = {
  __typename?: 'EthernetUniqueSettingsValidation';
  ipAddress?: Maybe<ValidationOptions>;
  hostname?: Maybe<ValidationOptions>;
  vlans?: Maybe<Array<Maybe<VlanValidationOptions>>>;
};

export type ValidationOptions = {
  __typename?: 'ValidationOptions';
  required: Scalars['Boolean'];
};

export type VlanValidationOptions = {
  __typename?: 'VlanValidationOptions';
  ipAddress?: Maybe<ValidationOptions>;
  hostname?: Maybe<ValidationOptions>;
};

export type WelcomeScreenUniqueSettingsValidation = {
  __typename?: 'WelcomeScreenUniqueSettingsValidation';
  displayName?: Maybe<ValidationOptions>;
};

export type CalendarUniqueSettingsValidation = {
  __typename?: 'CalendarUniqueSettingsValidation';
  exchangeSettings?: Maybe<UniqueExchangeSettingsValidation>;
  office365Settings?: Maybe<UniqueOffice365SettingsValidation>;
  googleSettings?: Maybe<UniqueGoogleSettingsValidation>;
};

export type UniqueExchangeSettingsValidation = {
  __typename?: 'UniqueExchangeSettingsValidation';
  impersonationMailbox?: Maybe<ValidationOptions>;
  delegationMailbox?: Maybe<ValidationOptions>;
  userAccount?: Maybe<ValidationOptions>;
  userPassword?: Maybe<SecretValidation>;
};

export type SecretValidation = {
  __typename?: 'SecretValidation';
  secret?: Maybe<ValidationOptions>;
};

export type UniqueOffice365SettingsValidation = {
  __typename?: 'UniqueOffice365SettingsValidation';
  username?: Maybe<ValidationOptions>;
};

export type UniqueGoogleSettingsValidation = {
  __typename?: 'UniqueGoogleSettingsValidation';
  roomMailbox?: Maybe<ValidationOptions>;
};

export type WifiUniqueSettingsValidation = {
  __typename?: 'WifiUniqueSettingsValidation';
  ipAddress?: Maybe<ValidationOptions>;
  wapSsid?: Maybe<ValidationOptions>;
};

export type SettingsGroupSearchOptions = {
  settingsGroupType?: Maybe<SettingsGroupType>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type Language = {
  __typename?: 'Language';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type ScheduledTaskSearchOptions = {
  taskType: TaskType;
};

export enum TaskType {
  DisplayUpdate = 'displayUpdate',
  UsbFirmwareUpdate = 'usbFirmwareUpdate',
  Reboot = 'reboot',
  BootAllUsers = 'bootAllUsers',
  ClearScreen = 'clearScreen',
  Wake = 'wake',
  Suspend = 'suspend',
  ForgetWifiNetworks = 'forgetWifiNetworks'
}

export type ScheduledTask = {
  __typename?: 'ScheduledTask';
  id: Scalars['ID'];
  type: TaskType;
  updateType: ScheduledTaskUpdateType;
  startDate: Scalars['String'];
  finishedOn?: Maybe<Scalars['String']>;
  data: TaskOptions;
  createdBy?: Maybe<Scalars['String']>;
  displays?: Maybe<Array<Maybe<DisplayUpdateTask>>>;
};

export enum ScheduledTaskUpdateType {
  Scheduled = 'scheduled',
  Now = 'now'
}

export type TaskOptions = {
  __typename?: 'TaskOptions';
  versionToUpdate?: Maybe<Scalars['String']>;
};

export type DisplayUpdateTask = {
  __typename?: 'DisplayUpdateTask';
  downloadPercentage?: Maybe<Scalars['Float']>;
  status: TaskStatus;
  errorMessage?: Maybe<Scalars['String']>;
  display?: Maybe<PartialDisplay>;
  displayId: Scalars['String'];
};

export enum TaskStatus {
  Pending = 'pending',
  Downloading = 'downloading',
  Installing = 'installing',
  Completed = 'completed',
  Failed = 'failed',
  Installpending = 'installpending',
  Unknown = 'unknown'
}

export type PartialDisplay = {
  __typename?: 'PartialDisplay';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateDisplay: Display;
  /** Revert the current assigned template to be the current confirmed template */
  cancelPendingChanges: Display;
  /** Save a new template */
  createTemplate?: Maybe<Template>;
  /** Update an existing template */
  updateTemplate: UpdateTemplateResult;
  /** Delete an existing template */
  deleteTemplate: EmptyResponse;
  /** Assign one or many displays to a new template */
  assignTemplate: AssignTemplateResult;
  /** Unassign one or many displays from their existing settingsType template */
  unassignTemplate: UnassignTemplateResult;
  /** Create an Active Learning trial license */
  createActiveLearningTrialLicense: Array<Display>;
  /** Apply category to a display */
  applyCategory: Display;
  /** Create a scheduled task */
  createScheduledTask?: Maybe<ScheduledTask>;
  updateScheduledTask?: Maybe<ScheduledTask>;
  inviteUser: User;
  updateUserRole: User;
  deleteUser: User;
};


export type MutationUpdateDisplayArgs = {
  options: UpdateDisplayInput;
};


export type MutationCancelPendingChangesArgs = {
  options: CancelPendingChangesInput;
};


export type MutationCreateTemplateArgs = {
  template: CreateTemplateInput;
};


export type MutationUpdateTemplateArgs = {
  template: UpdateTemplateInput;
};


export type MutationDeleteTemplateArgs = {
  template: DeleteTemplateInput;
};


export type MutationAssignTemplateArgs = {
  options: AssignTemplateOptions;
};


export type MutationUnassignTemplateArgs = {
  options: UnassignTemplateOptions;
};


export type MutationCreateActiveLearningTrialLicenseArgs = {
  options: CreateActiveLearningTrialLicenseInput;
};


export type MutationApplyCategoryArgs = {
  options: CategoryApplication;
};


export type MutationCreateScheduledTaskArgs = {
  options: CreateScheduledTaskInput;
};


export type MutationUpdateScheduledTaskArgs = {
  options: UpdateScheduledTaskInput;
};


export type MutationInviteUserArgs = {
  options: InviteUserInput;
};


export type MutationUpdateUserRoleArgs = {
  options: UpdateUserRoleInput;
};


export type MutationDeleteUserArgs = {
  options: DeleteUserInput;
};

export type UpdateDisplayInput = {
  displayId: Scalars['String'];
  settingsGroupType: SettingsGroupType;
  configuration: Scalars['Config'];
  saveAsNewTemplate?: Maybe<Scalars['Boolean']>;
  newTemplateName?: Maybe<Scalars['String']>;
};

export type CancelPendingChangesInput = {
  displayId: Scalars['String'];
  settingsGroupType: SettingsGroupType;
};

export type CreateTemplateInput = {
  name: Scalars['String'];
  settingsGroupType: SettingsGroupType;
  configuration: Scalars['Config'];
};

export type UpdateTemplateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  previousRevisionId: Scalars['String'];
  configuration: Scalars['Config'];
};

export type UpdateTemplateResult = {
  __typename?: 'UpdateTemplateResult';
  template: Template;
  validation: UniqueSettingsCompatibility;
};

export type DeleteTemplateInput = {
  id: Scalars['ID'];
};

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  success: Scalars['Boolean'];
};

export type AssignTemplateOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  /** The new templateId */
  templateId: Scalars['String'];
};

export type AssignTemplateResult = {
  __typename?: 'AssignTemplateResult';
  template: Template;
  validation: UniqueSettingsCompatibility;
};

export type UnassignTemplateOptions = {
  displaySearchOptions?: Maybe<DisplaySearchOptions>;
  /** The settings group to remove remove from 1 or more displays */
  settingsGroupType: SettingsGroupType;
};

export type UnassignTemplateResult = {
  __typename?: 'UnassignTemplateResult';
  affectedIds: Array<Maybe<Scalars['String']>>;
};

export type CreateActiveLearningTrialLicenseInput = {
  displaySearchOptions: DisplaySearchOptions;
  email: Scalars['String'];
  emailTwo?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
};

export type CategoryApplication = {
  displayId: Scalars['String'];
  categoryId: Scalars['String'];
  optionId: Scalars['String'];
};

export type CreateScheduledTaskInput = {
  id: Scalars['ID'];
  data: CreateTaskOptions;
  startDate: Scalars['String'];
  displayIds: Array<Scalars['String']>;
  type: TaskType;
  updateType: ScheduledTaskUpdateType;
  durationSecs: Scalars['Int'];
};

export type CreateTaskOptions = {
  versionToUpdate?: Maybe<Scalars['String']>;
  sendEmailOnCompletion?: Maybe<Scalars['Boolean']>;
};

export type UpdateScheduledTaskInput = {
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  cancelledOn?: Maybe<Scalars['String']>;
};

export type InviteUserInput = {
  email: Scalars['String'];
  role: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateUserRoleInput = {
  email: Scalars['String'];
  role: Scalars['Int'];
};

export type DeleteUserInput = {
  email: Scalars['String'];
};

/** A representation of the Solstice Config broken up by SettingsGroupType. Each sub object is meant to be used in place of the Config scalar */
export type DisplayConfiguration = {
  __typename?: 'DisplayConfiguration';
  ADVANCED?: Maybe<AdvancedConfiguration>;
  CALENDAR?: Maybe<CalendarConfiguration>;
  DIGITAL_SIGNAGE?: Maybe<DigitalSignageConfiguration>;
  DISCOVERY?: Maybe<DiscoveryConfiguration>;
  ETHERNET?: Maybe<EthernetConfiguration>;
  FEATURES?: Maybe<FeaturesConfiguration>;
  POWER_MANAGEMENT?: Maybe<PowerManagementConfiguration>;
  PROXY?: Maybe<ProxyConfiguration>;
  TIME_LOCALE?: Maybe<TimeLocaleConfiguration>;
  UNIQUE_SETTINGS?: Maybe<UniqueSettingsConfiguration>;
  WELCOME_SCREEN?: Maybe<WelcomeScreenConfiguration>;
  WIFI?: Maybe<WifiConfiguration>;
  MESSAGE_CENTER?: Maybe<MessageCenterConfiguration>;
  SECURITY?: Maybe<SecurityConfiguration>;
};

export type AdvancedConfiguration = {
  __typename?: 'AdvancedConfiguration';
  enableGatewayCheck?: Maybe<Scalars['Boolean']>;
  enableQos?: Maybe<Scalars['Boolean']>;
  qosVideoDscp?: Maybe<Scalars['String']>;
  qosAudioDscp?: Maybe<Scalars['String']>;
  enableDailyRestart?: Maybe<Scalars['Boolean']>;
  dailyRestartTime?: Maybe<Scalars['String']>;
  enableHDCP?: Maybe<Scalars['Boolean']>;
  licenseAgree?: Maybe<Scalars['Boolean']>;
};

export type CalendarConfiguration = {
  __typename?: 'CalendarConfiguration';
  enabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CalendarType>;
  exchangeSettings?: Maybe<ExchangeSettings>;
  office365Settings?: Maybe<Office365Settings>;
  googleSettings?: Maybe<GoogleSettings>;
  showTitles?: Maybe<Scalars['Boolean']>;
  showOrganizers?: Maybe<Scalars['Boolean']>;
  updateInterval?: Maybe<Scalars['Int']>;
};

export enum CalendarType {
  Exchange = 'exchange',
  Office365 = 'office365',
  Office365Oauth = 'office365_oauth',
  Custom = 'custom',
  Google = 'google'
}

export type ExchangeSettings = {
  __typename?: 'ExchangeSettings';
  url?: Maybe<Scalars['String']>;
  ntlmDomain?: Maybe<Scalars['String']>;
  mailboxType?: Maybe<MailboxType>;
  userAccount?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Secret>;
  authenticationType?: Maybe<AuthenticationType>;
};

export enum MailboxType {
  Default = 'default',
  Delegation = 'delegation',
  Impersonation = 'impersonation'
}

export type Secret = {
  __typename?: 'Secret';
  secret?: Maybe<Scalars['String']>;
};

export enum AuthenticationType {
  Basic = 'basic',
  Ntlm = 'ntlm',
  Oauth = 'oauth'
}

export type Office365Settings = {
  __typename?: 'Office365Settings';
  tenantId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Secret>;
};

export type GoogleSettings = {
  __typename?: 'GoogleSettings';
  credentials?: Maybe<SecretFile>;
};

export type SecretFile = {
  __typename?: 'SecretFile';
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Secret>;
};

export type DigitalSignageConfiguration = {
  __typename?: 'DigitalSignageConfiguration';
  enabled: Scalars['Boolean'];
  url: Scalars['String'];
  startAfter: Scalars['Int'];
  screenOption: ScreenOption;
  instructionOption: InstructionOption;
};

export enum ScreenOption {
  Fullscreen = 'fullscreen',
  FooterOnly = 'footer_only',
  FooterAndOverlay = 'footer_and_overlay'
}

export enum InstructionOption {
  NetworkOnly = 'network_only',
  CalendarOnly = 'calendar_only',
  NetworkAndCalendar = 'network_and_calendar'
}

export type DiscoveryConfiguration = {
  __typename?: 'DiscoveryConfiguration';
  broadcast: Scalars['Boolean'];
  publishToSds: Scalars['Boolean'];
  sdsHosts: Array<Maybe<SdsHost>>;
};

export type SdsHost = {
  __typename?: 'SDSHost';
  hostname: Scalars['String'];
};

export type EthernetConfiguration = {
  __typename?: 'EthernetConfiguration';
  enableEthernet?: Maybe<Scalars['Boolean']>;
  networkName?: Maybe<Scalars['String']>;
  networkConfig: NetworkConfig;
  adminAccess?: Maybe<Scalars['Boolean']>;
  eapMethod?: Maybe<EapMethod>;
  phase2Auth?: Maybe<Phase2Auth>;
  caCertificate?: Maybe<File>;
  userCertificate?: Maybe<SecretFile>;
  identity?: Maybe<Scalars['String']>;
  password?: Maybe<Secret>;
  vlans?: Maybe<Array<Maybe<Vlan>>>;
};

export type NetworkConfig = {
  __typename?: 'NetworkConfig';
  ipType?: Maybe<IpType>;
  gateway?: Maybe<Scalars['String']>;
  networkPrefixLength?: Maybe<Scalars['Int']>;
  dns1?: Maybe<Scalars['String']>;
  dns2?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
};

export enum IpType {
  Dhcp = 'dhcp',
  Static = 'static'
}

export enum EapMethod {
  None = 'none',
  Peap = 'peap',
  Tls = 'tls',
  Ttls = 'ttls',
  Pwd = 'pwd'
}

export enum Phase2Auth {
  None = 'none',
  Pap = 'pap',
  Mschap = 'mschap',
  Mschapv2 = 'mschapv2',
  Gtc = 'gtc'
}

export type File = {
  __typename?: 'File';
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type Vlan = {
  __typename?: 'VLAN';
  enabled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['Int']>;
  adminAccess?: Maybe<Scalars['Boolean']>;
  networkConfig?: Maybe<NetworkConfig>;
};

export type FeaturesConfiguration = {
  __typename?: 'FeaturesConfiguration';
  enableScreenKey?: Maybe<Scalars['Boolean']>;
  hdmiInputMode?: Maybe<InputMode>;
  disallowModeratorMode?: Maybe<Scalars['Boolean']>;
  enableMiracastWifiDirect?: Maybe<Scalars['Boolean']>;
  enableMiracastInfrastructure?: Maybe<Scalars['Boolean']>;
  enableAndroidMirroring?: Maybe<Scalars['Boolean']>;
  enableAirplay?: Maybe<Scalars['Boolean']>;
  enableAirplayProxy?: Maybe<Scalars['Boolean']>;
  enableAirplayBluetooth?: Maybe<Scalars['Boolean']>;
  enableWebRTC?: Maybe<Scalars['Boolean']>;
  quickConnectAction?: Maybe<QuickConnectAction>;
  alignment?: Maybe<Alignment>;
  speakScreenKey?: Maybe<Scalars['Boolean']>;
  browserLookIn?: Maybe<BrowserLookIn>;
  enableSolsticeConferencing?: Maybe<Scalars['Boolean']>;
  enableLocationServices?: Maybe<Scalars['Boolean']>;
  enableOccupancyData?: Maybe<Scalars['Boolean']>;
};

export enum InputMode {
  Standard = 'standard',
  Persistent = 'persistent',
  Primary = 'primary'
}

export enum QuickConnectAction {
  LaunchAndConnect = 'launch_and_connect',
  LaunchAndSetSds = 'launch_and_set_sds',
  LaunchOnly = 'launch_only'
}

export enum Alignment {
  Grid = 'grid',
  Freeform = 'freeform',
  DetermineAtRuntime = 'determine_at_runtime'
}

export enum BrowserLookIn {
  Enabled = 'enabled',
  Disabled = 'disabled',
  DetermineAtRuntime = 'determine_at_runtime'
}

export type PowerManagementConfiguration = {
  __typename?: 'PowerManagementConfiguration';
  occupancyEnabled?: Maybe<Scalars['Boolean']>;
  occupancyDelayMinutes?: Maybe<Scalars['Int']>;
  enableOffHours?: Maybe<Scalars['Boolean']>;
  weekday?: Maybe<PowerManagementSettings>;
  weekend?: Maybe<PowerManagementSettings>;
  control?: Maybe<PowerManagementControlSettings>;
};

export type PowerManagementSettings = {
  __typename?: 'PowerManagementSettings';
  suspendAfter?: Maybe<Scalars['Int']>;
  allDay?: Maybe<Scalars['Boolean']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
};

export type PowerManagementControlSettings = {
  __typename?: 'PowerManagementControlSettings';
  controlMethod?: Maybe<PowerManagementControlTypes>;
  commandDisplayOn?: Maybe<Scalars['String']>;
  commandDisplayOff?: Maybe<Scalars['String']>;
};

export enum PowerManagementControlTypes {
  Hdmi = 'hdmi',
  Rs232 = 'rs232'
}

export type ProxyConfiguration = {
  __typename?: 'ProxyConfiguration';
  http: Proxy;
  https: Proxy;
};

export type Proxy = {
  __typename?: 'Proxy';
  enabled?: Maybe<Scalars['Boolean']>;
  ipAddress?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Secret>;
  exclusionList?: Maybe<Scalars['String']>;
  bypassOnSameSubnet?: Maybe<Scalars['Boolean']>;
};

export type TimeLocaleConfiguration = {
  __typename?: 'TimeLocaleConfiguration';
  enabled?: Maybe<Scalars['Boolean']>;
  hostname?: Maybe<Scalars['String']>;
  show24Hours?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type UniqueSettingsConfiguration = {
  __typename?: 'UniqueSettingsConfiguration';
  ETHERNET?: Maybe<UniqueEthernetConfiguration>;
  WELCOME_SCREEN?: Maybe<UniqueWelcomeScreenConfiguration>;
  WIFI?: Maybe<UniqueWifiConfiguration>;
  CALENDAR?: Maybe<UniqueCalendarConfiguration>;
};

export type UniqueEthernetConfiguration = {
  __typename?: 'UniqueEthernetConfiguration';
  ipAddress?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  vlans?: Maybe<Array<Maybe<UniqueEthernetVlan>>>;
};

export type UniqueEthernetVlan = {
  __typename?: 'UniqueEthernetVLAN';
  ipAddress?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
};

export type UniqueWelcomeScreenConfiguration = {
  __typename?: 'UniqueWelcomeScreenConfiguration';
  displayName: Scalars['String'];
};

export type UniqueWifiConfiguration = {
  __typename?: 'UniqueWifiConfiguration';
  ipAddress?: Maybe<Scalars['String']>;
  wapSsid?: Maybe<Scalars['String']>;
  wapDnsHostname?: Maybe<Scalars['String']>;
  existingNetworkHostname?: Maybe<Scalars['String']>;
};

export type UniqueCalendarConfiguration = {
  __typename?: 'UniqueCalendarConfiguration';
  exchangeSettings?: Maybe<UniqueExchangeSettings>;
  office365Settings?: Maybe<UniqueOffice365Settings>;
  googleSettings?: Maybe<UniqueGoogleSettings>;
};

export type UniqueExchangeSettings = {
  __typename?: 'UniqueExchangeSettings';
  userAccount?: Maybe<Scalars['String']>;
  userPassword?: Maybe<Secret>;
  impersonationMailbox?: Maybe<Scalars['String']>;
  delegationMailbox?: Maybe<Scalars['String']>;
};

export type UniqueOffice365Settings = {
  __typename?: 'UniqueOffice365Settings';
  username?: Maybe<Scalars['String']>;
};

export type UniqueGoogleSettings = {
  __typename?: 'UniqueGoogleSettings';
  roomMailbox?: Maybe<Scalars['String']>;
};

export type WelcomeScreenConfiguration = {
  __typename?: 'WelcomeScreenConfiguration';
  hdmiOutput?: Maybe<HdmiOutputModeType>;
  isModern?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<InstructionsDisplayType>;
  showAirplay?: Maybe<Scalars['Boolean']>;
  showMiracast?: Maybe<Scalars['Boolean']>;
  customInstructions?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<WelcomeScreenImage>>>;
  pbShowPresenceBar?: Maybe<Scalars['Boolean']>;
  pbShowRoomName?: Maybe<Scalars['Boolean']>;
  pbShowIPAddress?: Maybe<Scalars['Boolean']>;
  pbShowScreenKey?: Maybe<Scalars['Boolean']>;
  pbPersist?: Maybe<Scalars['Boolean']>;
  showClock?: Maybe<Scalars['Boolean']>;
  showRoomName?: Maybe<Scalars['Boolean']>;
  screenKey?: Maybe<Scalars['Boolean']>;
  textColor?: Maybe<Scalars['String']>;
  showWifi?: Maybe<Scalars['Boolean']>;
  showHostnameInsteadOfIp?: Maybe<Scalars['Boolean']>;
  showFullyQualifiedName?: Maybe<Scalars['Boolean']>;
  shouldAnimateBackground?: Maybe<Scalars['Boolean']>;
};

export enum HdmiOutputModeType {
  Mirror = 'mirror',
  Single = 'single',
  Span = 'span',
  SeamlessSpan = 'seamless_span'
}

export enum InstructionsDisplayType {
  ShowDefault = 'show_default',
  ShowCustom = 'show_custom',
  Hide = 'hide'
}

export type WelcomeScreenImage = {
  __typename?: 'WelcomeScreenImage';
  enabled: Scalars['Boolean'];
  position: Scalars['Int'];
  _16x9: Image;
  _32x9: Image;
};

export type Image = {
  __typename?: 'Image';
  hash: Scalars['String'];
};

export type WifiConfiguration = {
  __typename?: 'WifiConfiguration';
  enabled?: Maybe<Scalars['Boolean']>;
  allowAdmin?: Maybe<Scalars['Boolean']>;
  mode?: Maybe<WifiMode>;
  wapSettings?: Maybe<WapSettings>;
  wifiClientSettings?: Maybe<WifiClientSettings>;
};

export enum WifiMode {
  Wap = 'wap',
  WifiClient = 'wifi_client'
}

export type WapSettings = {
  __typename?: 'WapSettings';
  hideSsid?: Maybe<Scalars['Boolean']>;
  securityProtocol?: Maybe<WapSecurityProtocol>;
  wpa2password?: Maybe<Secret>;
  channel?: Maybe<Scalars['Int']>;
};

export enum WapSecurityProtocol {
  Open = 'open',
  Wpa = 'wpa'
}

export type WifiClientSettings = {
  __typename?: 'WifiClientSettings';
  ssid?: Maybe<Scalars['String']>;
  /** This should be either open, wep, wpa, 802.1x */
  securityProtocol?: Maybe<Scalars['String']>;
  ipConfiguration?: Maybe<NetworkConfig>;
  enterpriseSettings?: Maybe<EnterpriseSettings>;
};

export type EnterpriseSettings = {
  __typename?: 'EnterpriseSettings';
  eapMethod?: Maybe<EapMethod>;
  phase2Auth?: Maybe<Phase2Auth>;
  caCertificate?: Maybe<File>;
  userCertificate?: Maybe<SecretFile>;
  identity?: Maybe<Scalars['String']>;
  password?: Maybe<Secret>;
};

export type MessageCenterConfiguration = {
  __typename?: 'MessageCenterConfiguration';
  rssFeeds?: Maybe<Array<Maybe<MessageCenterRssFeed>>>;
  rssFeedCount?: Maybe<Scalars['Int']>;
  customMessage?: Maybe<MessageCenterMessage>;
  emergencyMessage?: Maybe<MessageCenterMessage>;
};

export type MessageCenterRssFeed = {
  __typename?: 'MessageCenterRSSFeed';
  enabled?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type MessageCenterMessage = {
  __typename?: 'MessageCenterMessage';
  enabled?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};

export type SecurityConfiguration = {
  __typename?: 'SecurityConfiguration';
  adminPassword?: Maybe<Secret>;
  enforcePasswordValidation?: Maybe<Scalars['Boolean']>;
  allowLocalConfiguration?: Maybe<Scalars['Boolean']>;
  allowWebBrowserConfiguration?: Maybe<Scalars['Boolean']>;
  deliverAppOn443?: Maybe<Scalars['Boolean']>;
  enableEncryption?: Maybe<Scalars['Boolean']>;
  displayCertificate?: Maybe<Certificate>;
  useCaCertificate?: Maybe<Scalars['Boolean']>;
  caCertificate?: Maybe<File>;
  redirectHTTPS?: Maybe<Scalars['Boolean']>;
};

export type Certificate = {
  __typename?: 'Certificate';
  cert?: Maybe<SecretFile>;
  key?: Maybe<SecretFile>;
  password?: Maybe<Secret>;
};

export enum CountType {
  MedianMeeting = 'MEDIAN_MEETING',
  BusiestHour = 'BUSIEST_HOUR',
  ActiveMeetings = 'ACTIVE_MEETINGS',
  UniqueUsers = 'UNIQUE_USERS'
}

export type ConfirmedDisplays = {
  __typename?: 'ConfirmedDisplays';
  compatibilityAggregate?: Maybe<DisplayCompatibilityAggregate>;
  totalRecords: Scalars['Int'];
};

export type WellnessAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type WellnessAddressesQuery = (
  { __typename?: 'Query' }
  & { wellnessAddresses?: Maybe<Array<Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'lat' | 'lng' | 'building' | 'nickname' | 'address_full' | 'num_of_displays' | 'percent_booked' | 'wellnessScore' | 'trafficScore'>
  )>>> }
);

export type WellnessDisplaysQueryVariables = Exact<{ [key: string]: never; }>;


export type WellnessDisplaysQuery = (
  { __typename?: 'Query' }
  & { wellnessDisplays?: Maybe<Array<Maybe<(
    { __typename?: 'WellnessDisplay' }
    & Pick<WellnessDisplay, 'name' | 'id' | 'lat' | 'lng' | 'address_id' | 'floor' | 'booked' | 'available' | 'trafficScore' | 'wellnessScore'>
  )>>> }
);


export const WellnessAddressesDocument = gql`
    query wellnessAddresses {
  wellnessAddresses {
    id
    lat
    lng
    building
    nickname
    address_full
    num_of_displays
    percent_booked
    wellnessScore
    trafficScore
  }
}
    `;
export type WellnessAddressesQueryResult = ApolloReactCommon.QueryResult<WellnessAddressesQuery, WellnessAddressesQueryVariables>;
export const WellnessDisplaysDocument = gql`
    query wellnessDisplays {
  wellnessDisplays {
    name
    id
    lat
    lng
    address_id
    floor
    booked
    available
    trafficScore
    wellnessScore
  }
}
    `;
export type WellnessDisplaysQueryResult = ApolloReactCommon.QueryResult<WellnessDisplaysQuery, WellnessDisplaysQueryVariables>;
import React ,{ useState }from 'react'
import {  useLeaflet } from 'react-leaflet'
import { LatLng } from 'leaflet'
import  WellnessDrawer  from '../WellnessComponents/WellnessDrawer/WellnessDrawer'
import styles from './ListView.module.scss'
import BuildingsDetails from '../WellnessComponents/BuildingDetails/BuildingDetails'
import { BuildingDetails } from '../WellnessComponents/BuildingDetails/BuildingDetailsTypes'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import PodsDetails from '../WellnessComponents/PodDetails/PodDetails'
import { PodDetails } from '../WellnessComponents/PodDetails/PodDetailsTypes'
const redWalkingMan = require('../images/Red.svg')
const yellowWalkingMan = require('../images/Yellow.svg')
const greenWalkingMan = require('../images/Green.svg')

type Props = {
  mapType: 'Buildings' | 'Pods'
  zoom: number,
  isOpen: boolean
  closeDrawer: () => void
  setMapView: (LatLngBounds) => void
  allPods:PodDetails[]  | undefined
  allBuildings:BuildingDetails[] | undefined
}

function ListView(props: Props) {
  const { map } = useLeaflet() 
  const [address, setAddresss] = useState<BuildingDetails | undefined>(undefined)
  const [mapType, setMapType] = useState< 'Buildings' | 'Pods'>( 'Buildings' )
   
  const trafficColor = (wellnessScore :number) => {
    if (wellnessScore > 2/3) {
          return redWalkingMan
    }else if (wellnessScore  > 1/3) {
          return yellowWalkingMan
    }else{
          return greenWalkingMan
    }
  }

  const setMap = (id : number) => {
    if(props.allPods){
      let lat =0
      let lng = 0 

      const pods = props.allPods.filter(el => el.address_id == id)
      pods.forEach(el => {
        lat = lat + el.lat
        lng = lng + el.lng
      })
      if (map){
        map.setView(new LatLng(lat / pods.length , lng / pods.length) , 17)
      }
    }
  }

  return (
        <WellnessDrawer
            isOpen={props.isOpen}
            closeDrawer={props.closeDrawer}
        >  
            <div className={styles.ListView}>
              {mapType === "Pods" ?
                <div  onClick={() => { setMapType("Buildings")}} >
                  {address && 
                    <div className={styles.returnToBuilding}> 
                          <div className={styles.iconTitle}>
                              <KeyboardBackspaceIcon fontSize={'default'} />
                              <span>{address.buildingNickname ?? address.buildingName }</span>
                          </div>
                          <div className={styles.trafficIconWrapper}>
                            <img className={styles.manIcon} src={trafficColor(address.wellnessScore) } alt="walkingman"/>
                          </div>
                    </div>
                  }
                  { address && props.allPods &&  
                      props.allPods.filter(el => el.address_id == address?.id)
                              .map(pod => {
                                return (
                                  <div className={styles.listItem} key={pod.id} >
                                          <PodsDetails data={pod} isAdmin={false} />
                                  </div>)
                              })    
                  }
                </div>
              :
                <>
                  {props.allBuildings &&
                      props.allBuildings.map((building)=>{
                            return (
                                    <div 
                                      key={building.id}
                                      className={styles.listItem} 
                                      onClick={() => {  
                                          setAddresss(building) 
                                          setMap(building.id)
                                          setMapType ("Pods")   
                                      }}
                                    > 
                                      <BuildingsDetails data={building}/>
                                    </div>
                                    )
                      })
                  }
                </>
              }
            </div>
        </WellnessDrawer>    
  )
}

export default ListView 
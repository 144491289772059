import React, { useEffect, useState } from 'react'
import styles from './BuildingDetails.module.scss'
import { BuildingDetails } from './BuildingDetailsTypes'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { pluralize } from '../PodDetails/PodDetails'
import { LatLng } from 'leaflet'

const redWalkingMan = require('../../images/Red.svg')
const yellowWalkingMan = require('../../images/Yellow.svg')
const greenWalkingMan = require('../../images/Green.svg')

type Props = {
  data: BuildingDetails
  userLocation?: LatLng | undefined
}

function BuildingsDetails (props: Props) {
  const {
    buildingName,
    buildingNickname,
    wellnessScore,
    percentageBooked,
    totalDisplays,
    trafficScore,
    lat,
    lng
  } = props.data

  const trafficColor = () => {
    if (wellnessScore > 2.0/3.0 ) {
      return redWalkingMan
    }
    if (wellnessScore > 1.0/3.0 && wellnessScore <= 2.0/3.0) {
      return yellowWalkingMan
    }
    if (wellnessScore < 1.0/3.0) {
      return greenWalkingMan
    }
  }
  
  const getTrafficScore =() => {
    let val =''
      if(trafficScore === '--' ){
        val= "No traffic today"
      }else {
        val= trafficScore+" traffic"
      }

      if (distance_mi && props.userLocation ){
        return distance_mi.toFixed(2) +' miles / ' + val
      }
      else return val
  }
  
  const [distance_mi, setDistance_mi] = useState( (props.userLocation && lat && lng )? props.userLocation.distanceTo(new LatLng(lat,lng))  * 0.00062137 : null)
  
  useEffect(() => {
    if ( distance_mi && props.userLocation && lat && lng ) {
        setDistance_mi(props.userLocation.distanceTo(new LatLng(lat,lng))  * 0.00062137)
    }
  }, [props.userLocation])

  return (
    <div className={styles.building}>
      <div className={styles.trafficIconWrapper}>
        <img className={styles.manIcon} src={trafficColor()} alt="trafficColor"/>
      </div>
      <div className={styles.buildingInfoWrapper}>
          <div className={styles.buildingName}>
            {buildingNickname || buildingName }
          </div>
          
          <div className={styles.trafficScore}>
            {trafficScore &&
                getTrafficScore()
            }
          </div>
          
          <div className={styles.details}>
            {totalDisplays && 
                <div className={styles.meetingIcon}>
                  <MeetingRoomIcon fontSize={'default'} />
                  <span>{pluralize(totalDisplays,"room")}</span>
                </div>
            }
            {percentageBooked &&
                <div className={styles.calendarIcon}>
                  <CalendarTodayIcon fontSize={'small'}  />
                  <span>{percentageBooked.toFixed(1)}% booked</span>
                </div>
            } 
          </div>
      </div>
     
    </div>
  )
}

export default BuildingsDetails

export const baseURL = 'kepler.mersive.com'
export const baseBackendURL = 'http://localhost:4001'
export const baseAuthURL = 'kepler-auth-svc.mersive.com'

const config = {
  googleAnalytics: 'UA-16236886-4',
  hotjar: {
    id: 780960,
    snippetVersion: 6,
  },
  airbrake: {
    projectId: 173994,
    projectKey: 'f1ef26325226d6ccde8677090db23844',
  }
}

export const isLocalhost = (_hostname: string, _port: string): boolean => true

export const webSocketURL = (_hostname: string, _port: string): string => 'wss://localhost:8443/consume'

export const getAuthURL = (_hostname: string, _port: string): string => 'https://localhost:8443/auth'

export const getMxRenewURL = (_hostname: string, _port: string): string => 'https://localhost:8443/mxRenew'

export const isProduction = (): boolean => false

export const hasuraServerUrl = 'http://localhost:8080/v1/graphql'

export const hasuraServerWs = 'ws://localhost:8080/v1/graphql'

export default config
